<template>
    <v-container id="about" py-16 px-16>
        <v-row>
            <v-col class="text-center my-3">
                <div class="text-h4 font-weight-black my-3">
                    {{ title }}
                </div>
                <prismic-rich-text :field="description" />
            </v-col>
        </v-row>

        <v-row>
            <template v-for="(section, index) in sections">
                <v-col
                    :key="`${section.title}-${index}`"
                    xl="3"
                    cols="12"
                    class="text-center px-8"
                >
                    <v-icon color="#32b1ff" large>{{ section.icon }}</v-icon>
                    <div class="text-h5  mt-3">
                        {{ section.title }}
                    </div>
                    <p class="subheading mt-3">
                        <prismic-rich-text :field="section.description" />
                    </p>
                </v-col>
            </template>
        </v-row>

        <v-row class="text-center px-8 py-16">
            <PrismicRichText class="my-lg-16" :field="conclusion" />
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'HomeDetails',
    props: {
        title: { type: String, default: undefined },
        description: { type: Array, default: () => [] },
        sections: {
            type: [
                {
                    icon: String,
                    title: String,
                    description: {
                        type: Array,
                        default: () => []
                    }
                }
            ],
            default: undefined
        },
        conclusion: { type: Array, default: () => [] }
    }
};
</script>

<style scoped></style>
