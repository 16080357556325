<script>
import AppNavigation from '@/components/AppNavigation';

export default {
    name: 'App',
    components: {
        AppNavigation
    },
    data() {
        return {
            documentId: '',
            fields: {
                title: 'Sarona Services',
                logo: undefined
            }
        };
    },
    created() {
        this.getContent();
    },
    methods: {
        getContent() {
            this.$prismic.client.getSingle('home').then(document => {
                if (document) {
                    this.documentId = document.id;
                    this.fields.title = this.$prismic.richTextAsPlain(
                        document.data.title
                    );
                    this.fields.logo = document.data.logo.url;
                }
            });
        }
    }
};
</script>

<template>
    <v-app>
        <app-navigation
            :title="fields.title"
            :logo="fields.logo"
        ></app-navigation>
        <v-main class="body-content" transition="slide-x-transition">
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<style>
@import url('assets/fonts/frutiger.css');
@import url('assets/fonts/bebasneue.css');

.company--text {
    font-family: 'Frutiger';
    font-size: 1.3em;
}

.company--text__headline {
    /* font-family: 'Frutiger'; */
    letter-spacing: 4px;
    font-size: 1.5em;
}

.company--text__heandline_xs {
    font-family: 'Frutiger';
    letter-spacing: 0.5vw;
    font-size: 4vw;
}

.company--text__light {
    color: #fff;
}

.company--text__main {
    color: #32b1ff;
}

.company--text__secondary {
    color: #303030;
}

.company--text__title {
    font-family: 'Frutiger';
    font-weight: bold;
    font-size: 1.5em;
}

.company--color__background {
    background: rgba(48, 48, 48, 0.8);
}

.application--wrap {
    min-height: unset;
}

.body-content {
    height: 80vh;
}

.noDecoration {
    text-decoration: none;
    color: #fff;
}

.flex-column {
    flex-direction: column;
}

.bottom-shade {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 25%);
}

iframe[src*='.prismic.io/prismic-toolbar/'] {
    visibility: hidden;
}

div#prismic-toolbar-v2 {
    display: none;
}

.v-application a {
    color: inherit !important;
}

a:not(:hover) {
    text-decoration: none;
}
</style>
