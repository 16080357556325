import '@babel/polyfill';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import PrismicVue from '@prismicio/vue';

Vue.config.productionTip = false;

// Use your repository name
const endpoint = 'https://saronaservices.cdn.prismic.io/api/v2';

Vue.use(PrismicVue, {
    endpoint
});

new Vue({
    router,
    store,
    vuetify,
    mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
    render: h => h(App)
}).$mount('#app');
