<template>
    <v-container id="contact" mb-4>
        <v-row>
            <v-flex xs12 class="text-center text-h4 font-weight-black my-5"
                >Contact Us</v-flex
            >
        </v-row>
        <v-row justify="center">
            <v-col cols="10" sm="8" md="6" lg="4" class="company--text__main">
                <v-container>
                    <v-layout column>
                        <v-flex class="d-flex">
                            <v-icon color="#32b1ff" class="px-2"
                                >fas fa-envelope</v-icon
                            >
                            <PrismicRichText class="mt-4" :field="email" />
                        </v-flex>

                        <v-flex class="d-flex">
                            <v-icon color="#32b1ff" class="px-2"
                                >fas fa-phone</v-icon
                            >
                            <PrismicRichText class="mt-4" :field="telephone" />
                        </v-flex>

                        <v-flex class="d-flex">
                            <v-icon color="#32b1ff" class="pl-3 pr-2"
                                >fas fa-map-marker-alt</v-icon
                            >
                            <PrismicRichText class="mt-4" :field="address" />
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'HomeContact',
    props: {
        email: {
            type: Array,
            default: () => []
        },
        telephone: {
            type: Array,
            default: () => []
        },
        address: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style scoped></style>
