<template>
    <v-footer>
        <v-img
            :src="logo"
            max-height="125"
            max-width="90vw"
            contain
            class="my-4 mx-auto"
        ></v-img>
        <v-col class="text-center" cols="12">
            © {{ new Date().getFullYear() }} {{ companyName }} | {{ abn }}
        </v-col>
    </v-footer>
</template>

<script>
export default {
    name: 'AppFooter',
    props: {
        logo: {
            type: String,
            default: undefined
        },
        companyName: {
            type: String,
            default: undefined
        },
        abn: {
            type: String,
            default: undefined
        }
    }
};
</script>

<style scoped></style>
