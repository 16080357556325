<template>
    <v-container fluid class="home-hero">
        <v-row class="py-16 my-lg-16">
            <v-spacer lg="2" cols="1"></v-spacer>
            <v-col
                xl="4"
                lg="5"
                cols="10"
                class="d-flex flex-column justify-space-between"
            >
                <div class="d-flex mb-12">
                    <v-img
                        :src="logo"
                        max-height="80"
                        max-width="80"
                        contain
                        class="mr-2"
                    ></v-img>
                    <v-flex align-self-center
                        ><div class="text-h3 font-weight-black">
                            {{ title }}
                        </div></v-flex
                    >
                </div>
                <div class="mt-auto">
                    <div class="text-h3 font-weight-bold">
                        <PrismicRichText :field="text" />
                    </div>
                    <div class="text-h6 font-weight-light mb-12">
                        <PrismicRichText :field="description" />
                    </div>
                </div>
            </v-col>
            <v-spacer xl="1"></v-spacer>
            <v-col xl="6" lg="5" cols="9" align-self="end" class="mx-auto"
                ><v-img :src="image" contain></v-img>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'HomeHero',
    props: {
        title: { type: String, default: undefined },
        logo: { type: String, default: undefined },
        image: { type: String, default: undefined },
        text: { type: Array, default: () => [] },
        description: { type: Array, default: () => [] }
    }
};
</script>

<style scoped>
.home-hero--banner {
    width: 100vw;
    padding: 12px 0;
}
</style>
