<script>
import HomeHero from '@/components/HomeHero';
import HomeDetails from '@/components/HomeDetails';
import HomeContact from '@/components/HomeContact';
import AppFooter from '@/components/Footer';

export default {
    name: 'Home',
    components: {
        HomeHero,
        HomeDetails,
        HomeContact,
        AppFooter
    },
    data() {
        return {
            documentId: '',
            home: {
                title: null,
                logo: null,
                tagline: null,
                description: null,
                hero: null,
                button_link: null,
                button_label: null
            },
            about: {
                title: 'Sarona Services',
                description: undefined,
                sections: [],
                conclusion: undefined
            },
            contact: {
                email: undefined,
                telephone: undefined,
                address: undefined
            },
            slices: [],
            projects: [],
            footer: {
                companyName: 'Sarona Services Pty Ltd',
                abn: 'ABN 31 159 577 670'
            }
        };
    },
    created() {
        this.getContent();
    },
    methods: {
        getContent() {
            this.$prismic.client.getSingle('home').then(document => {
                if (document) {
                    this.documentId = document.id;
                    const data = document.data;
                    this.home.title = this.$prismic.richTextAsPlain(data.title);
                    this.home.logo = data.logo.url;
                    this.home.hero = data.hero.url;
                    this.home.tagline = data.tagline;
                    this.home.description = data.description;
                    this.contact.email = data.email;
                    this.contact.telephone = data.telephone;
                    this.contact.address = data.address;
                }
            });
            this.$prismic.client.getSingle('about').then(document => {
                if (document) {
                    this.documentId = document.id;
                    this.about.title = this.$prismic.richTextAsPlain(
                        document.data.title
                    );
                    this.about.description = document.data.description;
                    this.about.sections = document.data.sections.map(s => ({
                        icon: this.$prismic.richTextAsPlain(s.icon),
                        title: this.$prismic.richTextAsPlain(s.section_title),
                        description: s.section_description
                    }));
                    this.about.conclusion = document.data.conclusion;
                }
            });
        }
    }
};
</script>

<template>
    <span>
        <home-hero
            :title="home.title"
            :text="home.tagline"
            :description="home.description"
            :image="home.hero"
            :logo="home.logo"
        ></home-hero>
        <home-details
            :title="about.title"
            :description="about.description"
            :sections="about.sections"
            :conclusion="about.conclusion"
        ></home-details
        ><home-contact
            :email="contact.email"
            :telephone="contact.telephone"
            :address="contact.address"
        ></home-contact>
        <app-footer
            :logo="home.logo"
            :company-name="footer.companyName"
            :abn="footer.abn"
        ></app-footer>
    </span>
</template>

<style>
.homepage-banner {
    margin: -70px 0 80px;
    padding: 10em 0 8em;
    background-position: center center;
    background-size: cover;
    color: #ffffff;
    line-height: 1.75;
    text-align: center;
}

.banner-content {
    text-align: center;
}

.banner-title,
.banner-description {
    width: 90%;
    max-width: 490px;
    margin-left: auto;
    margin-right: auto;
}

.banner-title {
    color: #ffffff;
    font-size: 70px;
    font-weight: 900;
    line-height: 70px;
}

.banner-button {
    background: #ffffff;
    border-radius: 7px;
    color: #484d52;
    font-size: 14px;
    font-weight: 700;
    padding: 15px 40px;
}

.banner-button:hover {
    background: #c8c9cb;
}

/* Media Queries */
@media (max-width: 767px) {
    .homepage-banner {
        margin: 0 0 40px;
        padding: 10em 0 6em;
    }

    .banner-title {
        font-size: 50px;
        line-height: 50px;
    }

    .text-section-2col {
        -webkit-column-count: 1;
        /* Chrome, Safari, Opera */
        -moz-column-count: 1;
        /* Firefox */
        column-count: 1;
        -webkit-column-gap: 40px;
        /* Chrome, Safari, Opera */
        -moz-column-gap: 40px;
        /* Firefox */
        column-gap: 40px;
    }

    .quote {
        font-size: 20px;
    }

    .gallery-item {
        -webkit-box-flex: 100%;
        -moz-box-flex: 100%;
        -webkit-flex: 100%;
        -ms-flex: 100%;
        flex: 100%;
    }

    .highlight-left,
    .highlight-right {
        width: 100%;
        float: none;
    }
}
</style>
